@charset 'utf-8';

@import 'settings';
@import 'foundation';
@import 'motion-ui';

@include foundation-global-styles;
@include foundation-grid;
// @include foundation-flex-grid;
@include foundation-typography;
@include foundation-button;
@include foundation-forms;
// @include foundation-range-input;
@include foundation-accordion;
@include foundation-accordion-menu;
@include foundation-badge;
@include foundation-breadcrumbs;
@include foundation-button-group;
@include foundation-callout;
@include foundation-card;
@include foundation-close-button;
@include foundation-menu;
@include foundation-menu-icon;
@include foundation-drilldown-menu;
@include foundation-dropdown;
@include foundation-dropdown-menu;
@include foundation-responsive-embed;
@include foundation-label;
@include foundation-media-object;
@include foundation-off-canvas;
@include foundation-orbit;
@include foundation-pagination;
@include foundation-progress-bar;
// @include foundation-progress-element;
// @include foundation-meter-element;
@include foundation-slider;
@include foundation-sticky;
@include foundation-reveal;
@include foundation-switch;
@include foundation-table;
@include foundation-tabs;
@include foundation-thumbnail;
@include foundation-title-bar;
@include foundation-tooltip;
@include foundation-top-bar;
@include foundation-visibility-classes;
@include foundation-float-classes;
// @include foundation-flex-classes;

@include motion-ui-transitions;
@include motion-ui-animations;

///////////////////////////////////////////////////////////////////////////////////////////////////
// GENERAL
///////////////////////////////////////////////////////////////////////////////////////////////////
@import '../bower_components/font-awesome/scss/font-awesome';

.no-mb{ margin-bottom: 0; }
.no-mt{ margin-top: 0; }
.no-pb{ padding-bottom: 0; }
.no-pl{ padding-left: 0; }
.no-pr{ padding-right: 0; }

.mt-neg{ margin-top: rem-calc(-30); }
.mt1{ margin-top: rem-calc(10); }
.mt2{ margin-top: rem-calc(20); }
.mt3{ margin-top: rem-calc(30); }
.mt4{ margin-top: rem-calc(40); }
.mt5{ margin-top: rem-calc(50); }
.mt6{ margin-top: rem-calc(60); }

.mb1{ margin-bottom: rem-calc(10); }
.mb2{ margin-bottom: rem-calc(20); }
.mb3{ margin-bottom: rem-calc(30); }
.mb4{ margin-bottom: rem-calc(40); }
.mb5{ margin-bottom: rem-calc(50); }
.mb6{ margin-bottom: rem-calc(60); }

.mr1 { margin-right: rem-calc(10); }
.mr2 { margin-right: rem-calc(20); }
.mr3 { margin-right: rem-calc(30); }
.mr4 { margin-right: rem-calc(40); }
.mr5 { margin-right: rem-calc(50); }
.mr6 { margin-right: rem-calc(60); }

.pt05{ padding-top: rem-calc(5); }
.pt1{ padding-top: rem-calc(10); }
.pt2{ padding-top: rem-calc(20); }
.pt3{ padding-top: rem-calc(30); }
.pt4{ padding-top: rem-calc(40); }
.pt5{ padding-top: rem-calc(50); }
.pt6{ padding-top: rem-calc(60); }
.pb1{ padding-bottom: rem-calc(10); }
.pb2{ padding-bottom: rem-calc(20); }
.pb3{ padding-bottom: rem-calc(30); }
.pb4{ padding-bottom: rem-calc(40); }
.pb5{ padding-bottom: rem-calc(50); }
.pb6{ padding-bottom: rem-calc(60); }

.primary-bg{ background: rgba($primary-color, 0.9); }
.gray-bg{ background: $medium-gray; }
.light-gray-bg{ background: $light-gray; }
.light-gray-bg3{ background: $light-gray3; }
.white-bg{ background: rgba($white, 0.85); }
.black-bg{ background: rgba($black, 0.85); }

.white{ color: $white; }
.pink{ color: $primary-color; }
.black{ color: $black; }
.grey{ color: $medium-gray; }

.align-right{ text-align: right; }
.align-left{ text-align: left; }
.align-center{ text-align: center; }

.author{
	font-size: 3.6em;
	font-family: "shelby";
	line-height: 0.7em;
}

.bold{ font-weight: bold; }

.no-radius{ border-radius: 0; }

.arrow-right{
	padding-left: rem-calc(40);
	background-repeat: no-repeat;
	background-position: 9px 9px;
	line-height: 1.2rem;
}

.arrow-right-white{
	@extend .arrow-right;
	background-image: url(../img/svg/chevron-right-white.svg);
}

.arrow-right-black{
	@extend .arrow-right;
	background-image: url(../img/svg/chevron-right-black.svg);
}

.arrow-right-pink{
	@extend .arrow-right;
	background-image: url(../img/svg/chevron-right-pink.svg);
}

.icon-mb{ padding-right: 10px; }
.icon-retour{ margin-right: 5px; }
.link-retour{ line-height: rem-calc(14); }


.red{ background: red; }
.blue{ background: blue; }
.green{ background: green; }
.green{ background: green; }
.yellow{ background: yellow; }

hr{ border: 1px solid $light-gray; }
///////////////////////////////////////////////////////////////////////////////////////////////////
// TITRES
///////////////////////////////////////////////////////////////////////////////////////////////////
h2{
	margin-top: 0.8em;
	font-weight: lighter;
}
///////////////////////////////////////////////////////////////////////////////////////////////////
// MENU
///////////////////////////////////////////////////////////////////////////////////////////////////
.top-bar{
	a{	color: $medium-gray;	}
	.dropdown.menu>li.is-dropdown-submenu-parent>a::after{
		border-color: $medium-gray transparent transparent;
	}
}
.menu-logo a{
	display: block;
	width: 240px !important;
	height: 77px !important;
	background: url(../img/svg/logo.svg) no-repeat center;
	text-indent: -99999px;
	margin: 1rem 3rem 1rem 0;
}

.current-menu-item a, .current-menu-parent a{
	color: $primary-color;
}

///////////////////////////////////////////////////////////////////////////////////////////////////
// CONTENU
///////////////////////////////////////////////////////////////////////////////////////////////////

.play-image{
	display: block;
	width: 240px;
	height: 225px;
	margin: 240px auto 0 auto;
}

.home1{
	background: url(../img/jpg/home1.jpg);
	background-size: cover;
}
.home2{
	background: url(../img/jpg/home2.jpg);
	background-size: cover;
}
.home4{
	height: rem-calc(475);
	background: url(../img/jpg/home4.jpg);
	background-size: cover;
}

.mid-left{
   background: url(../img/jpg/home2.jpg) no-repeat;
   width: 50%;
   position: absolute;
   left: 0px;
	background-size: cover;
}
.mid-right{
   background: url(../img/jpg/home3.jpg) no-repeat;
   width: 50%;
   position: absolute;
   right: 0px;
	background-size: cover;
}

.titre-page-background{
	padding: 5rem 0;
	color: $white;
	background-color: rgba($black, 0.6) !important;
	position: relative;

	&:before{
		content: '';
		display: block;
		position: absolute;
		width: 100%;
		height: 100%;
		top: 0;
		left: 0;
		z-index: -1;
	}

	h1{
		font-size: 4.5rem;
		margin-bottom: 2rem;
		font-weight: lighter;
	}
}

.contact-background{
	&:before{
		background-image: url(../img/jpg/bg-contact.jpg);
		background-size: cover;
	}
}

.actus-background{
	&:before{
		background-image: url(../img/jpg/bg-actus.jpg);
		background-size: cover;
	}
}

.programmes-background{
	&:before{
		background-image: url(../img/jpg/bg-programmes.jpg);
		background-size: cover;
	}
}
.prix{
	font-size: 2em;
	font-weight: normal;
	text-align: center;
	padding: 3rem 0;
}
.accroche{
	font-size: 1.7rem;
}
.programme-telephone{
	font-size: 2.5rem;
}

.breadcrumb-bg{
	background: $light-gray;
	margin-bottom: 4rem;
}
.breadcrumb{
	padding: 1.5rem 0;
	font-size: 0.8rem;

	.breadcrumb-separator{
		display: inline-block;
		padding: 0 0.5rem;
		color: $light-gray2;
	}
}

// STRIPES ////////////////////////////////////////////////////////////////////////////////////////
.stripe{
	box-shadow: inset 0 0 0 100px rgba($black, 0.3);
	background-size: cover;
}
.stripe-financement{
	@extend .stripe;
	background-image: url(../img/jpg/financement.jpg);

}
.stripe-recrutement{
	@extend .stripe;
	background-image: url(../img/jpg/recrutement.jpg);
}
// TEMOIGNAGES ////////////////////////////////////////////////////////////////////////////////////
.orbit-container{

	height: 100% !important;

	.orbit-previous, .orbit-next{
		color: $white;
		background: $medium-gray;
		padding: 2rem;
		border-radius: 0;
	}
	.orbit-next{ right: 0px; }
	.orbit-previous{ left: 0px; }
}

.orbit-class{
	max-height: 100% !important;
}

.temoignage-container{

	.temoignage-titre{
		background: $medium-gray;
		color: $white;
		margin-bottom: 0;
		padding: 20px 30px;
	}

	.temoignage-infos-container{
		margin-bottom: 30px;
	}

	.temoignage-infos{
		background: $primary-color;
		padding-right: 0;
		height: 100%;
		padding: 0;
	}

	.temoignage-texte{
		background: $white;
		padding: 2rem;
		font-size: 0.9rem;
		line-height: 1.4rem;
		height: 100% !important;
		margin-bottom: 0;
	}

	.temoignage-play{
		background: $primary-color;
		text-align: center;
		height: 100%;
		padding: 2rem 1rem;

		.temoignage-play-button{
			color: $white;
			display: inline-block;

			img{
				margin-bottom: rem-calc(10);
			}
		}
	}

	.temoignage-photo{
		text-align: center;
		padding: 0;
		img{
			border: 10px solid $white;
			width: 100%;
			height: auto;
		}
	}
}

.temoignage{
	padding: 8rem 0;
	background-size: cover;
	height: 100%;
}

.video-container{
	position: relative;
	padding-bottom: 56.25%;
	padding-top: 30px;
	height: 0;
	overflow: hidden;

	& iframe,
	& object,
	& embed {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	}
}

// ACTUALITES /////////////////////////////////////////////////////////////////////////////////////
.actu-container{
	background: $light-gray;
	margin: rem-calc(60) 0;
	padding: 0;

	.actu-min{
		width: 100%;
		height: auto;
	}

	.actu-title{
		font-size: rem-calc(24);
		font-weight: 600;
		padding: rem-calc(30) rem-calc(30) rem-calc(10) rem-calc(30);
		line-height: 1.2em;
	}

	.actu-date{
		color: $light-gray2;
		padding: 0 rem-calc(30);
	}

	.actu-excerpt{
		color: $medium-gray;
		font-size: rem-calc(14);
		padding: 0 rem-calc(30);
	}

	.actu-link{
		display: block;
		width: 100%;
		height: rem-calc(60);
		background: $light-gray3;
		text-align: center;
		padding-top: 20px;
		font-size: rem-calc(14);
		margin-top: rem-calc(30);
	}
}

.wp-paginate .current{
	background: $primary-color !important;
	border: 1px solid $primary-color !important;
}

// ANNONCES /////////////////////////////////////////////////////////////////////////////////////
.annonce-container{

	margin-bottom: rem-calc(60);

	.annonce-titre{
		background: $light-gray3;

		h2{
			margin: 0.5em 0;
			font-size: 2.3rem;
			padding-left: 0.6rem;
		}
	}

	.annonce-min{
		width: 100% !important;
		height: 203px;
		background-size: cover;
	}

	.annonce-localisation{
		background: $light-gray2;
		color: $white;
		padding: rem-calc(10) rem-calc(25);
		margin-bottom: 1rem;
	}

	.annonce-infos{
		background: $light-gray;
		padding-bottom: 1rem;

		.annonce-description{
			font-size: 0.9em;

			p{	margin-bottom: 0; }
		}
	}

	.annonce-button{
		height: auto;
		line-height: rem-calc(40);
	}
}

// ANNONCE ////////////////////////////////////////////////////////////////////////////////////////
.annonce-cover{
	img{
		display: none !important;
	}
}

.prix-annonce{
	@extend .prix;
	padding: 0.5rem 0 1.5rem 0;
	border-bottom: 1px solid $light-gray3;
}

.caract-annonce{
	padding: 1rem !important;

	li{
		color: $primary-color;

		span{
			color: $dark-gray;
		}
	}
}

h5{
	font-size: 1.2rem;
	font-weight: bold;
}

// CARTE //////////////////////////////////////////////////////////////////////////////////////////
.carte{
	padding-right: 0;

	img{
		width: 100%;
	}
}

.carte-form{
	background: $light-gray;
	padding-top: 2rem;

	p{ text-align: center; }

	.carte-reset{
		color: $light-gray2;
		font-size: 0.9rem;
	}

	p.count-offres{
		margin-top: 3rem;
		margin-bottom: 0;
		font-size: 2rem;

		.count{
			color: $primary-color;
		}
	}
	.criteres{
		font-size: 0.9rem;
	}
}

.input-group-label{
	background: $light-gray3;
	font-size: 0.8rem;
}
.input-group-field, select{
	font-size: 0.9rem;
}


// CONTACT ////////////////////////////////////////////////////////////////////////////////////////
.contact-bloc{
	background: $light-gray;
	padding: 2rem 2rem !important;
	margin-bottom: 3rem;

	h3{
		color: $primary-color;
		font-weight: lighter;
		margin-bottom: 1rem;
	}

	.contact-adresse, .contact-telephone, .contact-fax{
		padding-left: rem-calc(30);
		position: relative;
	}

	.contact-telephone, .contact-fax{
		float: left;
		margin-right: rem-calc(50);
		margin-bottom: 0;
	}

	.contact-icon{
		display: inline-block;
		position: absolute;
		left: 0;
	}
}

.form-bloc{
	background: $light-gray3;
	padding: 2rem 2rem !important;
	margin-bottom: 6rem;

	h3{
		color: $secondary-color;
		font-weight: lighter;
		margin-bottom: 1rem;
	}
}

///////////////////////////////////////////////////////////////////////////////////////////////////
// FOOTER
///////////////////////////////////////////////////////////////////////////////////////////////////
footer{
	border-top: $primary-color 5px solid;
}
.medium-gray{
	color: $medium-gray !important;
	&:hover{
		color: $primary-color !important;
	}
}

.post-footer{
	background: $medium-gray;
	text-align: center;
	color: $white;
	padding-top: 10px;
	padding-bottom: 10px;
	font-size: 0.9rem;
}

.telephone{
	font-size: 2em;
}

.menu-footer{
	a{
		color: $white;
	}
}
